<template>
    <div>
        <NavBreadcrumb></NavBreadcrumb>
        <div class="panel-style">
            <el-form :inline="true" :model="searchForm" >
                <el-form-item label="归属机构" prop="officeId">
                    <el-select v-model="searchForm.orgId" placeholder="请选择用户机构" clearable>
                        <el-option v-for="item in orgOption" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="登录名" prop="loginName">
                    <el-input v-model="searchForm.loginName" placeholder="登录名" />
                </el-form-item>
                <el-form-item label="用户名" prop="userName">
                    <el-input v-model="searchForm.userName" placeholder="用户名" />
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="searchForm.email" placeholder="邮箱" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="queryClientList">查询</el-button>
                    <el-button type="primary" @click="openAddClientPage">添加</el-button>
                </el-form-item>
            </el-form>
            <el-table :data="tableData" border size="small" stripe style="width: 100%"
                      :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="listLoading">
                <el-table-column prop="orgName" label="归属机构" />
                <el-table-column prop="loginName" label="登录名" />
                <el-table-column prop="userName" label="用户名" />
                <el-table-column prop="email" label="邮箱" :formatter="emailFormat"/>
                <el-table-column prop="type" label="用户类型" width="90px" :formatter="userTypeFormat"/>
                <el-table-column prop="updateTime" label="最近修改时间" width="140px" :formatter="dateFormat"/>
                <el-table-column label="操作" width="300px">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="openEditClientPage(scope.$index, scope.row)">修改</el-button>
                        <el-button size="mini" @click="openAssignClientPage(scope.$index, scope.row)">分配基站</el-button>
                        <el-button size="mini" @click="handleDelete(scope.$index, scope.row)"  type="danger">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="margin-top: 15px;text-align: right;">
                <el-pagination background layout="total, prev, pager, next, jumper" :total="tableTotal"
                               @current-change="handleCurrentChange" :current-page.sync="curr"/>
            </div>
            <el-dialog title="新增用户" :visible.sync="addFormVisible" custom-class="dialog-size" center :close-on-click-modal="false" v-if="addFormVisible" top="20vh">
                <el-form :model="addForm" :rules="formRules" ref="addForm"  label-width="100px"  :inline="true" >
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="归属机构" prop="orgId">
                                <el-select v-model="addForm.orgId" placeholder="请选择用户机构" style="width:250px">
                                    <el-option v-for="item in orgOption" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="登录名" prop="loginName">
                                <el-input v-model="addForm.loginName" placeholder="如:ChenQiang09" autocomplete="off" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="userName">
                                <el-input v-model="addForm.userName" placeholder="如:ChrisJiang" autocomplete="off" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="密码" prop="password">
                                <el-input type="password" v-model="addForm.password" placeholder="如:123456chen" autocomplete="new-password" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="确认密码" prop="rePassword">
                                <el-input type="password" v-model="addForm.rePassword" placeholder="如:123456chen" autocomplete="new-password" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="邮箱" prop="email">
                                <el-input v-model="addForm.email" placeholder="如:346255525@qq.com" autocomplete="off" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="联系电话" prop="phone">
                                <el-input v-model="addForm.phone" autocomplete="off" placeholder="如:15151665539" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="handleAdd" :loading="addLoading">立即提交</el-button>
                    <el-button @click="addFormVisible = false">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog title="修改用户" :visible.sync="editFormVisible" custom-class="dialog-size" center :close-on-click-modal="false">
                <el-form :model="editForm" :rules="formRules" ref="editForm" label-width="100px" :inline="true" >
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="归属机构" prop="orgId">
                                <el-select v-model="editForm.orgId" placeholder="请选择用户机构" style="width:250px">
                                    <el-option v-for="item in orgOption" :key="item.id" :label="item.name" :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="登录名" prop="loginName">
                                <el-input v-model="editForm.loginName" :disabled="true" autocomplete="off" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="姓名" prop="userName">
                                <el-input v-model="editForm.userName" autocomplete="off" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="密码" prop="password">
                                <el-input type="password" placeholder="不修改则不填" v-model="editForm.password" autocomplete="off" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="确认密码" prop="rePassword">
                                <el-input type="password" placeholder="不修改则不填" v-model="editForm.rePassword" autocomplete="off" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="邮箱" prop="email">
                                <el-input v-model="editForm.email" autocomplete="off" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="电话" prop="phone">
                                <el-input v-model="editForm.phone" autocomplete="off" style="width:250px"/>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button type="primary" @click="handleEdit" :loading="editLoading">立即修改</el-button>
                    <el-button @click="editFormVisible = false">取消</el-button>
                </div>
            </el-dialog>
            <el-dialog title="分配基站" :visible.sync="assignPageVisible" custom-class="dialog-size-assign" center :close-on-click-modal="false"  :before-close="assignHandleClose">
                <el-form :inline="true" :model="dialogSearchForm" >
                    <el-form-item label="归属机构" prop="officeId">
                        <el-select v-model="dialogSearchForm.orgId" placeholder="请选择用户机构" clearable>
                            <el-option v-for="item in dialogOrgOption" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="归属地区" prop="name">
                        <el-cascader :props="props" clearable v-model="dialogSearchForm.areaId"></el-cascader>
                    </el-form-item>
                    <el-form-item label="基站名称" prop="stationName">
                        <el-input v-model="dialogSearchForm.stationName" placeholder="基站名称" />
                    </el-form-item>
                    <el-form-item label="是否分配" prop="isAssign">
                        <el-select v-model="dialogSearchForm.isAssign" clearable>
                            <el-option v-for="item in isAssignOption" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="queryStationListInfo">查询</el-button>
                    </el-form-item>
                </el-form>
                <el-table :data="dialogTableData" border size="small" stripe style="width: 100%"
                          :header-cell-style="{background:'#eef1f6',color:'#606266'}" v-loading="dialogLoading">
                    <el-table-column prop="stationNumber" label="基站编号" />
                    <el-table-column prop="name" label="基站名称" />
                    <el-table-column prop="orgName" label="所属机构" />
                    <el-table-column prop="area.provCn" label="所属省" />
                    <el-table-column prop="area.cityCn" label="所属市" />
                    <el-table-column prop="area.districtCn" label="所属区" />
                    <el-table-column prop="createTime" label="创建时间" :formatter="dateFormat"/>
                    <el-table-column prop="isAssign" label="是否分配" width="100">
                        <template slot-scope="scope">
                            <div v-if="scope.row.isAssign=='0'">未分配</div>
                            <div v-else>已分配</div>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作">
                        <template slot-scope="scope">
                            <div v-if="scope.row.isAssign=='1'">
                                <el-button size="mini" type="primary" disabled>分配</el-button>
                                <el-button size="mini" @click="revokeStationAuth(scope.$index, scope.row)" type="danger">取消</el-button>
                            </div>
                            <div v-else>
                                <el-button size="mini" @click="assignStation(scope.$index, scope.row)" type="primary">分配</el-button>
                                <el-button size="mini" type="danger" disabled>取消</el-button>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="margin-top: 15px;text-align: right;">
                    <el-pagination background layout="total, prev, pager, next, jumper" :total="dialogTableTotal"
                                   @current-change="dialogHandleCurrentChange" :current-page.sync="dialogCurr"/>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import {addClient, queryClientByParam, queryClientList, modifyClient, deleteClient, queryStationList,
        queryClientOrgListByOrgId, assignStationToClient, deleteStationAuth, queryOrgInfo, queryCascaderAreaInfo} from "../../api/station/clientApi";
    import NavBreadcrumb from "../../components/NavBreadcrumb";
    import global from "../../components/Global";

    var that = {};
    export default {
        name: "ClientManage",
        components: {NavBreadcrumb},
        data() {
            let validateLoginName = (rule, value, callback) => {

                if (this.addFormVisible) {

                    if (value === '') {

                        callback(new Error('请输入登录名'));
                    } else {

                        const reg = /^[a-zA-Z0-9_-]{4,16}$/;
                        if (reg.test(value)) {

                            let params = {

                                loginName : value
                            };
                            queryClientByParam(params).then((res) => {

                                if (JSON.stringify(res) === "null") {

                                    callback();
                                } else {

                                    return callback(new Error('登录名已经存在'));
                                }
                            });
                        } else {
                            return callback(new Error('请输入4到16位（字母，数字，下划线，减号）'));
                        }
                    }
                } else {

                    callback();
                }

            };
            // 自定义校验密码
            let validatePass = (rule, value, callback) => {

                if (this.addFormVisible) {

                    if (value === '') {

                        callback(new Error('请输入密码'));
                    } else {

                        if (this.addForm.rePassword !== '') {
                            this.$refs.addForm.validateField('rePassword');
                        }
                        callback();
                    }
                } else if (this.editFormVisible) {

                    this.$refs.editForm.validateField('rePassword');
                    callback();
                }
            };
            // 自定义校验确认密码
            let validatePass2 = (rule, value, callback) => {

                if (this.addFormVisible) {

                    if (value === '') {

                        callback(new Error('请再次输入密码'));
                    } else if (value !== this.addForm.password) {

                        callback(new Error('两次输入密码不一致!'));
                    } else {
                        callback();
                    }
                } else if (this.editFormVisible) {

                    if (value !== this.editForm.password) {

                        callback(new Error('两次输入密码不一致!'));
                    } else {

                        callback();
                    }
                }
            };
            // 自定义校验手机号
            let validatePhone = (rule, value, callback) => {

                if (value === '') {

                    callback();
                } else {

                    const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
                    if (reg.test(value)) {
                        callback();
                    } else {
                        return callback(new Error('请输入正确的手机号'));
                    }
                }
            };
            return {
                tableData:[],
                tableTotal: 0,
                listLoading: false,
                curr: 1,
                searchForm: {

                  orgId: '',
                  loginName: '',
                  userName:'',
                },
                orgOption: [],
                addForm: {},
                addFormVisible: false,
                addLoading: false,
                editForm: {},
                editLoading: false,
                editFormVisible: false,
                formRules: {
                    orgId: [
                      { required: true, message: '请选所属机构', trigger: ['blur', 'change'] }
                    ],
                    loginName: [
                      { required: true, validator: validateLoginName, trigger: 'blur' }
                    ],
                    password: [
                      { required:true, validator: validatePass, trigger: 'blur' }
                    ],
                    rePassword: [
                      { required:true, validator: validatePass2, trigger: 'blur' }
                    ],
                    userName: [
                      { required: true, message: '请输入姓名', trigger: 'blur' }
                    ],
                    email: [
                      { required: false, message: '请输入邮箱', trigger: 'blur' },
                      { type: 'email', message: '请输入正确的邮箱地址', trigger: 'blur' }
                    ],
                    phone: [
                      { required: false, validator: validatePhone, trigger: 'blur' }
                    ]
                },
                assignPageVisible: false,
                dialogSearchForm: {
                    orgId: '',
                    areaId: [],
                    stationName: '',
                    isAssign: ''
                },
                dialogTableData: [],
                dialogTableTotal: 0,
                dialogCurr: 1,
                isAssignOption: [
                    {
                        'id': '0',
                        'name': '未分配'
                    },
                    {
                        'id': '1',
                        'name': '已分配'
                    }
                ],
                props: {

                    lazy: true,
                    checkStrictly: true,
                    lazyLoad (node, resolve) {

                        let params = {

                            level: node.level,
                            areaId: node.value
                        };
                        queryCascaderAreaInfo(params).then((res) => {

                            resolve(res.data.list);
                        }).catch(err => console.error(err));
                    }
                },
                dialogLoading: false,
                dialogOrgOption: [],
                clientId: '',
                clientOrgId: ''
            }
        },
        methods: {

            getTableData() {

                let params = {

                    curr: this.curr,
                    size: 10,
                    orgId: this.searchForm.orgId,
                    loginName: this.searchForm.loginName,
                    userName: this.searchForm.userName,
                    email: this.searchForm.email
                };
                this.listLoading = true;
                queryClientList(params).then((res) => {

                    this.listLoading = false;
                    this.tableTotal = res.total;
                    this.tableData = res.list;
                }).catch(err => console.error(err));
            },
            // 处理分页
            handleCurrentChange(val) {

                this.curr = val;
                this.getTableData();
            },
            openAddClientPage() {

                this.addForm = {

                    orgId: '',
                    loginName: '',
                    password: '',
                    rePassword: '',
                    userName: '',
                    email: '',
                    phone:''
                };
                this.addFormVisible = true;
            },
            handleAdd() {

                this.$refs["addForm"].validate((valid) => {

                    if (valid) {
                        console.log("tianjia1");
                        this.addLoading = true;
                        addClient(this.addForm).then((res) => {

                            this.addLoading = false;
                            if (res.code == global.response_status_success_msg) {

                                this.addFormVisible = false;
                                this.$message({

                                    message: res.msg,
                                    type: 'success'
                                });
                                this.getTableData();
                            } else if (res.code != global.response_status_auth_msg) {

                                this.$confirm(res.msg, '添加失败', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                            }
                        }).catch(err => {

                            console.error(err);
                        });
                    } else {

                        return false;
                    }
                });
            },
            // 打开编辑用户页面
            openEditClientPage(index, row) {

                this.editFormVisible = true;
                this.editForm = {

                    id:'',
                    orgId: '',
                    loginName: '',
                    password: '',
                    rePassword: '',
                    userName: '',
                    email: '',
                    phone: ''
                };
                let params = {

                    id : row.id
                };
                queryClientByParam(params).then((res) => {

                    this.editForm.id = res.id;
                    this.editForm.orgId = res.orgId;
                    this.editForm.loginName = res.loginName;
                    this.editForm.userName = res.userName;
                    this.editForm.email = res.email;
                    this.editForm.phone = res.phone;
                    this.editForm.password = "";
                    this.editForm.rePassword = "";
                });
            },
            handleEdit() {

                this.$refs["editForm"].validate((valid) => {

                    if (valid) {
                        console.log(this.editForm);
                        this.editLoading = true;
                        modifyClient(this.editForm).then((res) => {

                            this.editLoading = false;
                            if (res.code == global.response_status_success_msg) {

                                this.editFormVisible = false;
                                this.$message({

                                    message: res.msg,
                                    type: 'success'
                                });
                                this.getTableData();
                            } else if (res.code != global.response_status_auth_msg) {

                                this.$confirm(res.msg, '修改失败', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                            }
                        }).catch(err => {

                            console.error(err);
                        });
                    } else {

                        return false;
                    }
                });
            },
            handleDelete(index, row) {

                this.$confirm('是否确定删除？', '提示', {cancelButtonClass: "btn-custom-cancel"}).then(() => {
                    let params = {

                        id : row.id
                    };
                    deleteClient(params).then((res) => {

                        if (res.code == global.response_status_success_msg) {

                            this.$message({

                                message: res.msg,
                                type: 'success'
                            });
                            this.getTableData();
                        } else if (res.code != global.response_status_auth_msg) {

                            this.$confirm(res.msg, '删除失败', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                        }
                    }).catch(err => {

                        console.error(err);
                    });
                });
            },
            // 获取机构列表
            getOrgList() {

                let params = {

                    curr: 0,
                    size: 0
                };
                queryOrgInfo(params).then((res) => {
                    this.orgOption = res.data.list;
                }).catch(err => console.error(err));
            },
            queryClientList() {

                this.curr = 1;
                this.getTableData();
            },
            openAssignClientPage(index, row) {

                this.dialogSearchForm = {

                        orgId: '',
                        areaId: [],
                        stationName: '',
                        isAssign: ''
                },
                this.assignPageVisible = true;
                this.clientId = row.id;
                this.clientOrgId = row.orgId;
                this.getDialogOrgList(row.orgId);
                this.getDialogTableData();
            },
            getDialogOrgList(orgId) {

                let params = {
                    orgId: orgId
                };
                queryClientOrgListByOrgId(params).then((res) => {

                    this.dialogOrgOption = res.list;
                }).catch(err => console.error(err));
            },
            // 查询此用户所属机构下所有电站
            getDialogTableData() {

                var searchAreaId = "";
                if (this.dialogSearchForm.areaId.length == 3) {
                    searchAreaId = this.dialogSearchForm.areaId[2];
                } else if (this.dialogSearchForm.areaId.length == 2) {
                    searchAreaId = this.dialogSearchForm.areaId[1];
                } else if (this.dialogSearchForm.areaId.length == 1){
                    searchAreaId = this.dialogSearchForm.areaId[0];
                }
                let params = {

                    curr: this.dialogCurr,
                    size: 10,
                    searchOrgId: this.dialogSearchForm.orgId,
                    areaId: searchAreaId,
                    stationName: this.dialogSearchForm.stationName,
                    isAssign: this.dialogSearchForm.isAssign,
                    orgId: this.clientOrgId,
                    id: this.clientId
                };
                this.dialogLoading = true;
                queryStationList(params).then((res) => {

                    this.dialogLoading = false;
                    this.dialogTableTotal = res.total;
                    this.dialogTableData = res.list;
                    console.log(res.list);
                }).catch(err => console.error(err));
            },
            dialogHandleCurrentChange(val) {

                this.dialogCurr = val;
                this.getDialogTableData();
            },
            queryStationListInfo() {

                this.curr = 1;
                this.getDialogTableData();
            },
            /**
             * 分配电站权限.
             */
            assignStation(index, row) {

                let params = {

                    id: this.clientId,
                    stationId: row.id
                };
                this.dialogLoading = true;
                assignStationToClient(params).then((res) => {

                     this.dialogLoading = false;
                    if (res.code == global.response_status_success_msg) {

                        this.$message({

                            message: res.msg,
                            type: 'success'
                        });
                        this.getDialogTableData();
                    } else if (res.code != global.response_status_auth_msg) {

                        this.$confirm(res.msg, '分配失败', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                    }
                }).catch(err => {

                    console.error(err);
                });
            },
            /**
             * 撤销权限.
             */
            revokeStationAuth(index, row) {

                let params = {

                    id: this.clientId,
                    stationId: row.id
                };

                deleteStationAuth(params).then((res) => {

                    if (res.code == global.response_status_success_msg) {

                        this.$message({

                            message: res.msg,
                            type: 'success'
                        });
                        this.getDialogTableData();
                    } else if (res.code != global.response_status_auth_msg) {

                        this.$confirm(res.msg, '取消分配失败', {cancelButtonClass: "btn-custom-cancel"}).then(() => {});
                    }
                }).catch(err => {

                    console.error(err);
                });
            },
            assignHandleClose() {
                this.assignPageVisible = false;
                this.areaId= [];
                this.props = {};
            },
            emailFormat(row, column, cellValue) {

                if (cellValue === '') {

                    return '--'
                }
                return cellValue;
            },
            userTypeFormat(row, column, cellValue) {

                if (cellValue === 0) {

                    return '普通用户'
                } else {

                    return '职能用户'
                }
            },
            dateFormat(row, column, cellValue) {

                // 日期格式化
                let date = new Date(cellValue);
                // 日期格式不足两位补零
                function appendZero(str) {

                    if(str < 10) {

                        str = "0" +""+ str
                    }
                    return str;
                }
                return date.getFullYear()  + "-" + appendZero(date.getMonth() + 1) + "-" + appendZero(date.getDate()) + " "
                    + appendZero(date.getHours()) + ":" + appendZero(date.getMinutes())  + ":" + appendZero(date.getSeconds());
            },
        },
        mounted() {
            this.getOrgList();
            this.getTableData();
        },
        created() {
            that = this;
        }
    }
</script>

<style lang="scss">

    .dialog-size-assign {

        width: 1400px;
        height: 750px;
    }
</style>
