import axios from 'axios';
import global from '../../components/Global';


let base = global.serverBase;
export const addClient = params => { return axios.post(base + '/client/addClient', params)};
export const modifyClient = params => { return axios.post(base + '/client/modifyClient', params)};
export const deleteClient = params => { return axios.post(base + '/client/deleteClient', params)};
export const queryClientByParam = params => {return axios.post(base + '/client/queryClientByParam', params).then(res => res.data); };
export const queryClientList = params => {return axios.post(base + '/client/queryClientList', params).then(res => res.data); };
export const queryStationList = params => {return axios.post(base + '/client/queryStationList', params).then(res => res.data); };
export const queryClientOrgListByOrgId = params => {return axios.post(base + '/client/queryClientOrgListByOrgId', params).then(res => res.data); };
export const assignStationToClient = params => { return axios.post(base + '/client/assignStationToClient', params)};
export const deleteStationAuth = params => { return axios.post(base + '/client/deleteStationAuth', params)};
export const queryStationAreaInfoByOrgId = params => { return axios.post(base + '/client/queryStationAreaInfoByOrgId', params)};

export const queryOrgInfo = params => { return axios.post(base + '/client/queryOrgInfo', params)};
export const queryCascaderAreaInfo = params => { return axios.post(base + '/client/queryCascaderAreaInfo', params)};


